import React, { useState } from 'react';
import './Styles/SubscriptionPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const SubscriptionPage = () => {
    const [currentPlan, setCurrentPlan] = useState('Free'); // Default to Free plan
    const [paymentDetails, setPaymentDetails] = useState({ amount: 500, expiryDate: '' });
    const [isPaid, setIsPaid] = useState(false); // New state to track if the payment was successful

    const handleUpgrade = () => {
        handlePayment(); // Call the payment function
    };

    const handlePayment = () => {
        const options = {
            key: 'rzp_test_jZllf5L14roJi4', // Replace with your Razorpay Key ID
            amount: paymentDetails.amount * 100, // Amount is in currency subunits (i.e., paise)
            currency: 'INR',
            name: 'Matrimonial Site',
            description: 'Upgrade to Paid Plan',
            handler: function (response) {
                alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                // Update current plan and payment details after successful payment
                const today = new Date();
                const expiryDate = new Date(today.setFullYear(today.getFullYear() + 1));
                setCurrentPlan('Paid');
                setPaymentDetails({ amount: 500, expiryDate: expiryDate.toLocaleDateString() });
                setIsPaid(true); // Set payment status to true
            },
            prefill: {
                name: 'Your Name',
                email: 'you@example.com',
                contact: '9999999999'
            },
            theme: {
                color: '#F37254'
            }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    return (
        <div className="subscription-container">
            <h2>Subscription Plans</h2>
            <div style={{ marginBottom: '20px' }}>
                <h3>Current Plan: {isPaid ? currentPlan : 'Free'}</h3>
                {isPaid && (
                    <div>
                        <p>Payment Amount: ₹{paymentDetails.amount}</p>
                        <p>Expiry Date: {paymentDetails.expiryDate}</p>
                    </div>
                )}
            </div>
            <h4>Available Plans:</h4>
            <ul>
                <li>Free Plan: Unlimited access to basic features</li>
                <li>Paid Plan: ₹500/year - Access to premium features</li>
            </ul>
            <button onClick={handleUpgrade} disabled={isPaid}>
    <FontAwesomeIcon icon={faArrowUp} /> Upgrade to Paid Plan
</button>
        </div>
    );
};

export default SubscriptionPage;
