import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importing new icons
import { faHouse, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';// faSignInAlt, faUserPlus,

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector('.navbar').contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-brand" onClick={() => navigate('/home')}>
        Karanki Matrimony
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <button onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faHouse} /> Home
        </button>
          {/* <button onClick={() => navigate('/login')}>
          <FontAwesomeIcon icon={faSignInAlt} /> Login
        </button>
        <button onClick={() => navigate('/register')}>
          <FontAwesomeIcon icon={faUserPlus} /> Register
        </button> 
         */}
        <button onClick={() => navigate('/about-us')}>
          <FontAwesomeIcon icon={faInfoCircle} /> About Us
        </button>
        <button onClick={() => navigate('/contact-us')}>
          <FontAwesomeIcon icon={faPhone} /> Contact Us
        </button>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
