import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; 
import { doc, getDoc, collection, getDocs, arrayUnion, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import Navbar from '../Components/Navbar';
import PhotoModal from '../Components/PhotoModal'; 
import './Styles/Home.css';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faUser,faUsers } from '@fortawesome/free-solid-svg-icons';
import { FaSave, FaTimes } from 'react-icons/fa';

const Home = () => {
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false); // Track user activation
  const [oppositeGenderUsers, setOppositeGenderUsers] = useState([]);
  const [userPhotos, setUserPhotos] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploading, setUploading] = useState(false); // New state for upload loading
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(userData.fullName || 'User');
            setUserPhotos(userData.photos || []);
            setIsActive(userData.isActive || false); // Check if user is active

            if (userData.isActive) { // Fetch matches only if the account is active
              const usersCollectionRef = collection(db, 'users');
              const usersSnapshot = await getDocs(usersCollectionRef);
              const allUsers = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

              const filteredUsers = allUsers.filter(u => 
                u.gender !== userData.gender &&
                (userData.partnerPreferenceType === 'NRI' 
                  ? u.partnerPreferenceType === 'NRI'
                  : userData.partnerPreferenceType === 'India' 
                    ? u.partnerPreferenceType === 'India' && u.state === userData.partnerstate 
                    : u.state === userData.partnerstate) &&
                (userData.partnerprofessionSector === 'All' || !userData.partnerprofessionSector || u.professionSector === userData.partnerprofessionSector) &&
                (userData.caste === u.caste) &&
                (userData.religion === u.religion)
              );
 // Sort users by createdAt in descending order
              const sortedUsers = filteredUsers.sort((a, b) => b.createdAt - a.createdAt);

              setOppositeGenderUsers(sortedUsers);
            }
          } else {
            toast.error('User data not found.');
          }
        } catch (error) {
          toast.error('Failed to fetch user data.');
        }
        setLoading(false);
      } else {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhoto(file);
    }
  };

  const handleSavePhoto = async () => {
    if (newPhoto) {
      setUploading(true); // Start loading state for upload
      const storage = getStorage();
      const storageRef = ref(storage, `photos/${auth.currentUser.uid}/${newPhoto.name}`);
      
      try {
        await uploadBytes(storageRef, newPhoto);
        const downloadURL = await getDownloadURL(storageRef);
        
        await updateDoc(doc(db, 'users', auth.currentUser.uid), {
          photos: arrayUnion(downloadURL),
        });

        setUserPhotos(prev => [...prev, downloadURL]);
        toast.success('Photo uploaded successfully.');
        setNewPhoto(null);
      } catch (error) {
        toast.error('Photo upload failed: ' + error.message);
      } finally {
        setUploading(false); // Reset loading state
      }
    }
  };

  const handleCancelPhoto = () => {
    setNewPhoto(null);
  };

  const handleProfileClick = async (userId) => {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      recentlyViewed: arrayUnion(userId),
    });
    navigate(`/user/${userId}`);
  };

  const handlePhotoClick = () => {
    if (userPhotos.length > 0) {
      setSelectedPhoto(userPhotos[0]);
    }
  };

  const closePhotoModal = () => {
    setSelectedPhoto(null);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  // Show account verification message if account is not active
  if (!isActive) {
    return (
      <div className="verification-container">
       <h1 className="verification-heading">
  <FontAwesomeIcon icon={faClock} style={{ marginRight: '10px', color: '#FFA500' }} />
  Your account is under verification
</h1>
        <p>Your account will be approved by the admin within 24 hours or more. Please check back later.</p>
        <Link to="/profile">
  <button className="profile-button">
    <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
    Go to My Profile
  </button>
</Link>
      </div>
    );
  }

  const profileCompletionPercentage = userPhotos.length > 0 ? 100 : 75;

  return (
    <div>
      <Navbar />
      <div className="home-page">
        <div className="home-content">
          <h1 className="welcome-message">Welcome, {userName}!</h1>
          <h2 className="profile-completion">Profile Completion: {profileCompletionPercentage}%</h2>

          {profileCompletionPercentage === 75 && (
            <div className="photo-upload-section">
              <input type="file" onChange={handlePhotoUpload} accept="image/*" className="file-input" />
              {newPhoto && (
                <div className="selected-photo-info">
                <p className="selected-photo-text">Selected Photo: {newPhoto.name}</p>
                <button onClick={handleSavePhoto} className="save-button">
                  <FaSave style={{ marginRight: '5px' }} /> Save
                </button>
                <button onClick={handleCancelPhoto} className="cancell-button">
                  <FaTimes style={{ marginRight: '5px' }} /> Cancel
                </button>
              </div>
              )}
              {uploading && <p className="uploading-message">Uploading photo...</p>} {/* Loading message */}
              <p className="upload-prompt">Upload your photo to complete your profile to 100%!</p> {/* New upload prompt */}
            </div>
          )}

          <div className="profile-photo-container">
            {userPhotos.length > 0 ? (
              <img
                src={userPhotos[0]}
                alt="Profile"
                className="profile-photo"
                onClick={handlePhotoClick}
              />
            ) : (
              <div className="profile-placeholder">No Profile Photo</div>
            )}
          </div>
         
          <hr />

          <h2 className="matches-title">
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: '8px' }} />
            Matches
        </h2>
          <div className="matches-grid">
            {oppositeGenderUsers.length === 0 ? (
              <p className="no-users-message">No matches found.</p>
            ) : (
              oppositeGenderUsers.map((user) => (
                <div key={user.id} onClick={() => handleProfileClick(user.id)} className="user-card">
                  <div className="user-photo-container">
                    {user.photos && user.photos.length > 0 ? (
                      <img src={user.photos[0]} alt={`${user.fullName}'s profile`} className="user-photo" />
                    ) : (
                      <div className="user-photo-placeholder">No Photo</div>
                    )}
                  </div>
                  <p className="user-name">{user.fullName}</p>
                  <div className="user-details">
                    <p className="user"><strong>Date of birth:</strong> {user.dateOfBirth}</p>
                    <p className="user"><strong>Height:</strong> {user.height}</p>
                    <p className="user"><strong>Education:</strong> {user.education}</p>
                    <p className="user"><strong>Native State:</strong> {user.state}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {selectedPhoto && <PhotoModal photoUrl={selectedPhoto} onClose={closePhotoModal} />}
      <Footer/>
    </div>
  );
};

export default Home;
