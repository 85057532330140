import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './Styles/About.css'; // Import the CSS file for styling
import MainNav from './MainNav';
import Footer from './Footer';
const About = () => {
    return (
        <div>
            <MainNav/>
        <div className="about-us-container">
            <Helmet>
                <title>About Karanki Matrimony - Best Gowda Matrimony Service</title>
                <meta
                    name="description"
                    content="Karanki Matrimony offers the best matrimony service for the Gowda community. Join us for top matchmaking and meaningful relationships."
                />
                <meta name="keywords" content="Karanki Matrimony, best matrimony, top Gowda marriage, Gowda matchmaking, Gowda community, marriage service" />
            </Helmet>

            <header className="about-us-header">
                <h1 className="about-us-title">About Karanki Matrimony</h1>
                <p className="about-us-subtitle">Start your journey to find the perfect match.</p>
            </header>

            <section className="about-us-content">
                <div className="about-us-mission">
                    <h2 className="about-us-section-title">Our Mission</h2>
                    <p className="about-us-text">
                        At Karanki Matrimony, our goal is to provide a reliable platform for people looking for meaningful relationships. We connect individuals in the Karanki community to help families unite through marriage.
                    </p>
                </div>

                <div className="about-us-who-we-are">
                    <h2 className="about-us-section-title">Who We Are</h2>
                    <p className="about-us-text">
                        Karanki Matrimony is created by a team of dedicated professionals. We value cultural traditions in Gowda & other marriage and use technology to support your journey in finding a partner.
                    </p>
                </div>

                <div className="about-us-why-choose-us">
                    <h2 className="about-us-section-title">Why Choose Us</h2>
                    <ul className="about-us-list">
                        <li className="about-us-list-item">Detailed profiles to help you make informed choices.</li>
                        <li className="about-us-list-item">Your privacy and security are our top priorities.</li>
                        <li className="about-us-list-item">A user-friendly interface for an easy experience.</li>
                        <li className="about-us-list-item">Support at every step of your marriage journey.</li>
                    </ul>
                </div>
            </section>

            <footer className="about-us-footer">
                <p className="about-us-footer-text">Join us today and start your journey to love within the Karanki community!</p>
            </footer>
        </div>
        <Footer/>
        </div>
    );
};

export default About;
